* {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/img/backgrounds/noisy-background.png');
}

#auth-input {
  font-size: 1.5rem;
  text-align: center;
  box-sizing: border-box;
}
#auth-input  #auth-form input {
  margin: 0 5px;
  text-align: center;
  font-size: 50px;
  border: solid 1px #33b5e5;
  box-shadow: 0 0 5px #33b5e5 inset;
  outline: none;
  width: 20%;
  transition: all 0.4s ease-in-out;
  border-radius: 3px;
}
#auth-input  #auth-form input:focus {
  border-color: #ff5722;
  box-shadow: 0 0 5px #ff5722 inset;
}
#auth-input  #auth-form input::-moz-selection {
  background: transparent;
}
#auth-input  #auth-form input::selection {
  background: transparent;
}

h1 {
  font-size: 3rem;
}

b,
strong {
  font-size: 1.5rem;
}

p,
a,
label,
button,
h3,
li,
.links {
  font-size: 1.5rem;
  margin: 0;
}

.custom-control-label {
  font-size: 1.1rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  font-weight: 400;
}

.custom-checkbox .custom-control-label::before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 0rem;
}

.custom-control-label::after {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
}

.add-item-width {
  width: calc(100% - 2rem);
}

.item-sidenav-height {
  max-height: calc(100vh - 27rem);
  overflow: auto;
}

/* For alert transition */
@-webkit-keyframes swing {
  15% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  50% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  65% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  80% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes swing {
  15% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  50% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  65% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  80% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.alert {
  z-index: 3000;
  position: fixed;
  bottom: 0;
  right: 0;
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@media (max-width: 992px) {
  b {
    font-size: 1rem;
  }

  .sidebar-position {
    position: relative;
  }

  .sidenav-height {
    max-height: calc(100vh - 10rem);
    overflow: auto;
  }
}

@media (min-width: 992px) {
  .sidebar-position {
    position: fixed;
  }

  .sidenav-height {
    max-height: calc(100vh - 12rem);
    overflow: auto;
  }
}
